<style lang="less">
.el-tree {
    color: #334681;
}
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
    background-color: #f5faff;
    color: #279aff;
    .el-tree-node__expand-icon {
        color: #279aff;
    }
    .el-tree-node__expand-icon.is-leaf {
        color: transparent;
    }
}
</style>
<template>
    <div class="tree">
        <el-input
            placeholder="请输入分组名"
            v-model="filterText"
            size="mini"
            style="margin-bottom: 18px"
        >
            <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-tree
            :data="GroupData"
            node-key="id"
            default-expand-all
            :indent="16"
            current-node-key=""
            :expand-on-click-node="false"
            :props="{}"
            @node-click="clickTree"
            :highlight-current="true"
            :filter-node-method="filterNode"
            ref="tree"
        >
            <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>

                <div class="edit-btns" @click.stop>
                    <i
                        class="el-icon-plus"
                        style="color: blue"
                        v-show="isMove"
                        @click.stop="toFile(data)"
                    ></i>
                    <el-dropdown v-show="!isMove" v-if="impoType == 1">
                        <span class="el-dropdown-link">
                            <i class="el-icon-more"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native="open(data)"
                                >添加项目</el-dropdown-item
                            >
                            <el-dropdown-item
                                @click.native="delt(node, data)"
                                v-if="data.id != ''"
                                >删除项目</el-dropdown-item
                            >
                            <el-dropdown-item
                                @click.native="moveMeun(node, data)"
                                v-if="data.id != ''"
                                >移动项目</el-dropdown-item
                            >
                            <el-dropdown-item
                                @click.native="resetName(node, data)"
                                v-if="data.id != ''"
                                >重命名</el-dropdown-item
                            >
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </span>
        </el-tree>
    </div>
</template>

<script>
import Bus from "@/utils/eventBus";
import {
    getMuenTree,
    moveMaterial,
    delMuen,
    addMuen,
    removeMuen,
    treeList,
    getMuen,
    resetMeunName,
} from "@/api/api.js";
export default {
    name: "",
    props: {
        material: {
            // 设置默认值
            default: "Material",
            // 指定传值类型
            type: String,
            // 是否必须传值,父组件不传值会报警告
            required: true,
        },
        impoType: {
            default: 1, //1，素材库引入  2，视频导入
            type: Number,
        },
    },
    data() {
        return {
            filterText: "",
            isMove: false, //是否移动
            moveId: "",
            type: this.material == "Material" ? 2 : 1,
            currentKey: "",
            checkDefault: [1],
            GroupData: [
                {
                    label: "所有素材",
                    id: "",
                    children: [],
                },
            ],
        };
    },
    created() {
        // this.getMuenList(this.GroupData);
        // 获取文件夹结构
        this.getMeunTree();
        Bus.$on("sendId", (id) => {
            this.isMove = true;
            this.moveId = id;
        });
        // this.$nextTick(() => {
        // 设置节点选中
        //     // 等待挂载完成再进行下面这行代码
        //     // this.$refs.tree.setCurrentKey(0);
        // });
    },

    mounted() {},
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        },
    },
    methods: {
        resetName(node, data) {
            this.$prompt(`修改目录：${data.label}`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputPlaceholder: "请输入新目录名称",
                inputPattern: /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/,
                inputErrorMessage: "文件命不能包含特殊字符跟空格",
            }).then(({ value }) => {
                const req = {
                    id: data.id,
                    newName: value,
                };
                resetMeunName(req).then((res) => {
                    if (res.code == 200) {
                        this.$message.success("修改成功");
                        this.getMeunTree();
                        // this.list.fileName = value;
                    }
                });
            });
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        // 获取目录
        getMeunTree() {
            // console.log(this.material);
            let type = this.material == "mev" ? 1 : 2;

            getMuenTree({
                materialLibraryType: type,
            }).then((res) => {
                this.GroupData[0].children = res.data;
            });
        },
        async getMuenList(data) {
            let type = this.material == "mev" ? 1 : 2;
            let json = {
                materialLibraryType: type,
                "orders[0].column": "create_time",
            };
            let res = await getMuen(json);

            this.$set(data, "children", []);
            // res.data.records.map((item) => {
            //     // console.log(item);
            //     data.children.push(item);
            // });
            // console.log(data.children);
            // console.log(res.data.records);
            // data.children.push.apply(data.children, res.data.records);
            this.GroupData[0].children = res.data.records;
        },
        async clickTree(data, node) {
            // this.getMuenList(data);
            this.$emit("senData", data);
            // let type = this.material == "mev" ? 1 : 2;
            // let json = {
            //     materialLibraryType: type,
            //     "orders[0].column": "create_time",
            // };

            // if (data.id) {
            //     json.parentId = data.id;
            // }
            // // console.log(data);
            // let res = await getMuen(json);
            // this.$set(data, "children", []);
            // data.children.push.apply(data.children, res.data.records);
            // console.log(data);
        },
        // getSource(data) {
        //   getSource(data).then((res) => {
        //     this.list = res.data.list;
        //   });
        // },
        // 移动目录
        moveMeun(node, data) {
            // 开始移动
            this.isMove = true;
            this.selectMove = data;

            // 先删除当前节点
            this.removeMeun(node, data);
        },
        // 树节点移除
        removeMeun(node, data) {
            // 树节点移除
            const parent = node.parent;
            const children = parent.data.children || parent.data;
            const index = children.findIndex((d) => d.id === data.id);
            children.splice(index, 1);
        },
        // 接收节点
        async toFile(data) {
            this.toMove = data;
            // 接收目录ID
            let tid = this.toMove.id;
            // 移动目标
            if (this.moveId) {
                // 移动素材
                let json = {
                    targetMaterialLibraryId: tid,
                    materialId: this.moveId,
                };
                // console.log(json);
                let res = await moveMaterial(json);
                if (res.code == 200) {
                    this.$message({
                        type: "success",
                        message: "移动成功!",
                    });
                } else {
                    this.$message({
                        type: "error",
                        message: "移动失败!",
                    });
                }
            } else {
                // 移动目录
                let mid = this.selectMove.id;
                removeMuen(mid, tid).then((res) => {
                    this.getMeunTree();
                    if (res.code == 200) {
                        this.$message({
                            type: "success",
                            message: "移动成功!",
                        });
                    } else {
                        this.$message({
                            type: "error",
                            message: "移动失败!",
                        });
                    }
                });
            }
            // 添加节点
            // 操作完成
            this.isMove = false;
            this.moveId = "";
        },
        // 删除目录
        delt(node, data) {
            let mid = data.id;
            // console.log(mid);
            if (mid == 0) {
                this.$message({
                    type: "info",
                    message: "根目录无法删除",
                });
                return;
            }
            let fileName = data.label;
            this.$confirm(
                `将永久删除该 ${fileName} 以及下级所有关联文件, 是否继续?`,
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    delMuen(mid).then((res) => {
                        if (res.code == 200) {
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                            this.removeMeun(node, data);
                        } else {
                            this.$message({
                                type: "error",
                                message: "删除失败！",
                            });
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消操作",
                    });
                });
        },
        // 增加文件夹
        open(data) {
            this.$prompt("请输入文件夹名", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            })
                .then(({ value }) => {
                    if (value == null) {
                        this.$message({
                            type: "success",
                            message: "名称不能为空",
                        });
                        return;
                    }

                    let addData = {
                        materialLibraryName: value, //文件名
                        materialLibraryType: this.type, //目录类型 kuType
                        parentId: data.id == 0 ? null : data.id,
                    };

                    addMuen(addData).then((res) => {
                        if (res.code == 200) {
                            this.$message({
                                type: "success",
                                message: "创建文件夹成功！",
                            });
                            const newChild = {
                                id: res.data.id,
                                label: value,
                                children: [],
                            };
                            if (!data.children) {
                                this.$set(data, "children", []);
                            }
                            data.children.push(newChild);
                            // this.getMeunTree();
                        } else {
                            this.$message({
                                type: "error",
                                message: "创建失败!",
                            });
                        }
                    });
                    //
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "取消输入",
                    });
                });
        },
    },
};
</script>

<style lang="less" scoped>
.tree {
    padding: 20px;
}

.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}
</style>